import React from "react";
import { withPrefix, graphql } from "gatsby";

// Layouts
import Default from '../layouts/default';
import Seo from '../layouts/seo';

// Components
import Content, { HTMLContent } from "../components/content-renderer";
import PageHeader from '../components/page-header';
import Section from '../components/section';

const About = ({ data }) => {
  const { markdownRemark: pageInfo } = data;
  const PageContent = HTMLContent || Content;
  const headerImage = pageInfo.frontmatter.image.relativePath;

  return (
    <Default className='centre-content'>
      <Seo
        title={pageInfo.frontmatter.title}
        description={pageInfo.frontmatter.description}
      />

      <PageHeader
        heading={pageInfo.frontmatter.title}
        imageSource={`${withPrefix("/")}img/${headerImage}`}
        description={pageInfo.frontmatter.description}
      />

      <Section
        label='Main text content'
        variants={['padded']}
      >
        <section className='content-renderer'>
          <PageContent content={pageInfo.html} />
        </section>
      </Section>
    </Default>
  );
};

export default About;

export const aboutQuery = graphql`
  query About($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        image {
            relativePath
        }
        description
      }
    }
  }
`;